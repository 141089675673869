import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { questionStore } from '../stores/questionStore';
import { styled } from '@mui/material';

import { QuestionDetails, Tag } from '../models/questionModel';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

import clsx from 'clsx';
import { QuestionDetailTabs } from '../components/QuestionDetailTabs';
import { editorialStore } from '../stores/editorialStore';
import { Editorial, EditorialDetails } from '../models/editorialModel';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",

    },
    margin: {
      padding: theme.spacing(3, 0, 0, 0),
    },
    paper: {
      height: "100"
    },
    stack: {
      background: "transparent",
    },
    root: {
      a: {
        textDecoration: "none",
      },
      "&:hover": {
        backgroundColor: "#eee",

      }
    }
  }),
);

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const editorialDetails: EditorialDetails = {
  'body': `
  # System APIs
We can have SOAP or REST APIs to expose the functionality of our service. The following could be the definitions of the APIs for uploading and searching videos:

uploadVideo(api_dev_key, video_title, video_description, tags[], category_id, default_language, recording_details, video_contents)
### Parameters:
api_dev_key (string): The API developer key of a registered account. This will be used to, among other things, throttle users based on their allocated quota.
video_title (string): Title of the video.
video_description (string): Optional description of the video.
tags (string[]): Optional tags for the video.
category_id (string): Category of the video, e.g., Film, Song, People, etc.
default_language (string): For example English, Mandarin, Hindi, etc.
recording_details (string): Location where the video was recorded.
video_contents (stream): Video to be uploaded.

Returns: (string)
A successful upload will return HTTP 202 (request accepted) and once the video encoding is completed the user is notified through email with a link to access the video. We can also expose a queryable API to let users know the current status of their uploaded video.

searchVideo(api_dev_key, search_query, user_location, maximum_videos_to_return, page_token)
### Parameters:
api_dev_key (string): The API developer key of a registered account of our service.
search_query (string): A string containing the search terms.
user_location (string): Optional location of the user performing the search.
maximum_videos_to_return (number): Maximum number of results returned in one request.
page_token (string): This token will specify a page in the result set that should be returned.

Returns: (JSON)
A JSON containing information about the list of video resources matching the search query. Each video resource will have a video title, a thumbnail, a video creation date, and a view count.

streamVideo(api_dev_key, video_id, offset, codec, resolution)
Parameters:
api_dev_key (string): The API developer key of a registered account of our service.
video_id (string): A string to identify the video.
offset (number): We should be able to stream video from any offset; this offset would be a time in seconds from the beginning of the video. If we support playing/pausing a video from multiple devices, we will need to store the offset on the server. This will enable the users to start watching a video on any device from the same point where they left off.
codec (string) & resolution(string): We should send the codec and resolution info in the API from the client to support play/pause from multiple devices. Imagine you are watching a video on your TV’s Netflix app, paused it, and started watching it on your phone’s Netflix app. In this case, you would need codec and resolution, as both these devices have a different resolution and use a different codec.

Returns: (STREAM)
A media stream (a video chunk) from the given offset.
  `
}


const questionDetailsBody: string = `
# Design Netflix
Design a video streaming service like Youtube/Netflix where user can upload/view/search videos.
The service should be scalable where a large number of users can watch and share the videos simultaneously.
It will be storing and transmitting petabytes and petabytes of data.

Things to discuss and analyze:

Approach to record stats about videos e.g the total number of views, up-votes/down-votes, etc.
Adding comments on videos in real-time.

### Functional Requirements
  - Users should be able to upload videos.
  - Users should be able to share and view videos.
  - Users should be able to perform searches based on video titles.
  - Our services should be able to record stats of videos, e.g., likes/dislikes, total number of views, etc.
  - Users should be able to add and view comments on videos
### Non-Functional Requirements:
  - Users should be able to upload videos.
  - Users should be able to share and view videos.
  - Users should be able to perform searches based on video titles.
  - Our services should be able to record stats of videos, e.g., likes/dislikes, total number of views, etc.
  - Users should be able to add and view comments on videos
`;




export const QuestionDetailsPage  = observer(() =>  {
  const { questionId, editorialId } = useParams<'questionId' | 'editorialId'>();

  useEffect(() => {
    questionStore.loadQuestion(questionId!);
    editorialStore.loadEditorials(questionId!);
  }, []);
  const classes = useStyles();

  const questionDetails: QuestionDetails = questionStore.questionDetails;
  const editorials: Editorial[] = editorialStore.editorials;
  return (
    <Grid container className={clsx(classes.margin, classes.container)}>
      <Grid item sm={2}></Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={clsx(classes.paper)}>
          <QuestionDetailTabs questionDetails={questionDetails} editorials={editorials} />
        </Paper>
      </Grid>
      <Grid item sm={2}></Grid>
    </Grid>
  )
  });

