import {
	Button,
	Paper,
	FormControl,
	Typography,
	TextField,
	Link,
	Grid,
	useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import React from "react";
import { observer } from "mobx-react";
import { Alert } from "@mui/material";
import { userStore } from "../stores/userStore";


export const LandingPanel = observer(() => {
	const theme = useTheme();
	const [email, setEmail] = React.useState<string>("");
	const [errorMsg, setErrorMsg] = React.useState<string>("");
	const [isRegistered, setIsRegistered] = React.useState<boolean>(false);
	const validateEmail = (email: string): boolean => {
		var re = /\S+@\S+\.\S+/;
		return re.test(email);
	};

	const registerWaitlist = async (e: React.MouseEvent<HTMLElement>) => {
		if(!validateEmail(email)) {
			setErrorMsg("Invalid email");
			return;
		} else {
			setErrorMsg("");
		}
		await userStore.registerWaitlist(email);
		if(userStore.isError) {
			setErrorMsg("Something went wrong");
		} else {
			setIsRegistered(true);
		}
	};
	return (
		<Paper>
			<Grid container>
				<Grid item xs={12} sx={{padding: theme.spacing(3)}}>
					<Typography variant={"h3"}>
					Get better at software design interviews.
					Master scalability and system design.
					</Typography>
					<Typography variant='body1' sx={{margin: theme.spacing(4, 0)}}>
						We've put together some of the best system designers and ideas from FAANG interviewers.
						We're currently in private preview. Please enter you email to be notified.
					</Typography>
					{errorMsg !== "" ? (
						<Alert severity="error">
							{errorMsg}
						</Alert>
					) : (
						<React.Fragment />
					)}
					{
						!isRegistered ? (
							<React.Fragment>
								<FormControl fullWidth sx={{ margin: theme.spacing(2, 0) }}>
								<TextField
									placeholder="Email"
									variant="outlined"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									required
								/>
								</FormControl>
								<FormControl fullWidth sx={{ margin: theme.spacing(2, 0) }}>
								<Button
									variant="contained"
									color="secondary"
									onClick={registerWaitlist}
									disabled={userStore.isLoading}
								>
									Notify me!
								</Button>
								</FormControl>
							</React.Fragment>

						)
						:
						(
							<React.Fragment>
								<Alert severity="success">
								Thanks for signing up!
								</Alert>
							</React.Fragment>
						)
					}

				</Grid>
			</Grid>
		</Paper>
	);
});
