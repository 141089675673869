import {
	LoginRequest,
	LoginResponse,
	RegisterRequest,
	RegisterResponse,
	RegisterWaitlistRequest,
	RegisterWaitlistResponse,
} from "../models/userModel";
import {
	ListQuestionsRequest,
	ListQuestionsResponse,
	GetQuestionRequest,
	GetQuestionResponse,
} from "../models/questionModel";
import {
	ListSolutionsRequest,
	ListSolutionsResponse,
} from "../models/editorialModel";

import axios, { Axios } from "axios";
import { Editorial } from "../models/editorialModel";
import { Question, QuestionDetails } from "../models/questionModel";

export class APIClient {
	/**
	 *
	 */
	private httpClient: Axios;
	private baseUrl: string;

	constructor(httpClient: Axios) {
		this.httpClient = axios;
		this.baseUrl = "http://localhost:3000";
		this.httpClient.defaults.baseURL = this.baseUrl;
	}

	async login(req: LoginRequest): Promise<LoginResponse> {
		let response = await this.httpClient.post("/api/login", req, {
			withCredentials: true,
		});
		return response.data as LoginResponse;
	}

	async register(req: RegisterRequest): Promise<RegisterResponse> {
		let response = await this.httpClient.post("/api/register", req, {
			withCredentials: true,
		});
		return response.data as RegisterResponse;
	}

	async listQuestions(
		req: ListQuestionsRequest
	): Promise<ListQuestionsResponse> {
		try {
			let response = await this.httpClient.get("/api/questions", {
				withCredentials: true,
			});
			return response.data as ListQuestionsResponse;
		} catch (Error) {
			throw Error;
		}
	}

	async getQuestion(req: GetQuestionRequest): Promise<GetQuestionResponse> {
		try {
			const questionId = req.id;
			let response = await this.httpClient.get(`/api/questions/${req.id}`);
			return response.data as GetQuestionResponse;
		} catch (Error) {
			throw Error;
		}
	}

	async registerWaitlist(
		req: RegisterWaitlistRequest
	): Promise<RegisterWaitlistResponse> {
		try {
			let response = await this.httpClient.post("/api/waitlist", req);
			return response.data as RegisterWaitlistResponse;
		} catch (err) {
			throw err;
		}
	}

	async listSolutions(
		req: ListSolutionsRequest
	): Promise<ListSolutionsResponse> {
		try {
			const questionId = req.questionId;
			let response = await this.httpClient.get(
				`/api/questions/${req.questionId}/solutions`
			);
			return response.data as ListSolutionsResponse;
		} catch (Error) {
			throw Error;
		}
	}
}

export const apiClient = new APIClient(new Axios());
