import React from 'react';
import logo from './logo.svg';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Link } from '@mui/material';


export function Header(){
    return <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="primary">
        <Toolbar>
            <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            >
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            leetdesign.xyz
            </Typography>
						<Link href='/login'>
            	<Button color="inherit">Login</Button>
						</Link>
        </Toolbar>
        </AppBar>
    </Box>
}
